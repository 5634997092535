.LayoutHeader {
  // position: fixed;
  // z-index: 100;
  // top: 0;
  // left: 0;
  width: 100%;
  padding:2.5rem 4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.bg-color{
    &.transparent{background:transparent;}
    &.transition{background:rgba(0,0,0,0.3);}
  }
  &.bg-img {
    background-image: url('../../../assets/images/top-blue-bg.png');
    background-repeat: no-repeat;
    background-size: 100% ;
  }
  a{line-height: 1;}
  .left {
    height: 3.4rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    a{
      display: inline-block;
      height: 3.4rem;
      position: relative;
      top: 4px;
      .logo {
        width: 16.2rem;
        height: 4.2rem;
      }
    }
    .accountDelete {
      display: flex;
      align-items: center;
      padding:0 2rem;
      height: 2.4rem;
      margin-top: 0.4rem;
      box-sizing: border-box;
      border-left: 0.1rem solid #ffffff;
      font-size: 1.8rem;
      font-family: MiSans-Medium, MiSans;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 2.1rem;
    }
  }
  .right {
    display: flex;
    align-items: flex-end;
    gap: 30px;
    height: 3.4rem;
    // padding-bottom: 3px;
    box-sizing: border-box;
    a{display: inline-block;height: 2rem;}
    .item {
      > img {
        width: 20px;
        height: 20px;
        // margin-bottom: -2px;
      }
      .langText {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        color: #FFFFFF;
        line-height: 16px;
        cursor: default;
        .arrowDown {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
.langeDropdown {
  // transform: translateY(12px);
  padding-top: 12px;
  ul li{
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    &.ant-dropdown-menu-item-selected {
      color: #325CF1 !important;
      background-color: transparent !important;

    }
  }
}

@media screen and (max-width: 750px){
  .LayoutHeader {
    position: relative;
    padding: 3.2rem 2.3rem;
    &.bg-img {
      background-size: auto ;
    }
    .left {
      .logo {
        width: 16.2rem;
        height: auto;
        // height: 2.774rem;
      }
      .accountDelete{
        display: none;
      }
    }
    .right {
      align-items: center;
      .phone-hide {
        display: none;
      }
      img{width: 2rem;}
      .langText {
        gap: 9px;
        .arrowDown {
          margin-left: -5px;
        }
      }
    }
  }
}