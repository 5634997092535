.pdf-modal {
  .ant-modal-header {
    padding:0 24px 24px;
    margin: 0 -24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .ant-modal-footer{
    padding:24px 24px 0;
    margin: 0 -24px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    .footer {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-modal-content .ant-modal-body{
    margin: 0 -24px !important;
  }
  .pdf-viewer {
    width: 100%;
    height: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .pdf-document {
      flex: 1;
      width: 100%;
      overflow: auto;
      .loadding-content {
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    .textLayer,.annotationLayer {
      display: none;
    }
  }

  #preloader5{
    position:relative;
    width:20px;
    height:20px;
    background:#3498db;
    border-radius:50px;
    animation: preloader_5 1.5s infinite linear;
  }
  #preloader5:after{
      position:absolute;
      width:40px;
      height:40px;
      border: 10px solid ;
      border-color:rgba(156, 89, 182,.3) transparent rgba(156, 89, 182,.3) ;
      border-radius:50px;
      content:'';
      top:-20px;
      left:-20px;
      animation: preloader_5_after 1.5s infinite linear;
  }
  @keyframes preloader_5 {
      0% {transform: rotate(0deg);}
      50% {transform: rotate(180deg);background:rgba(50, 92, 241 ,.3);}
      100% {transform: rotate(360deg);}
  }
  @keyframes preloader_5_after {
      0% {border-top-color: rgba(156, 89, 182,.3);border-bottom-color: rgba(156, 89, 182,.3);}
      50% {border-top-color: rgba(52, 152, 219,.3);border-bottom-color: rgba(52, 152, 219,.3);}
      100% {border-top-color: rgba(156, 89, 182,.3);border-bottom-color: rgba(156, 89, 182,.3);}
  }
  
}

@media screen and (max-width: 750px){
  .ant-modal-header {
    padding:0 15px 15px;
    margin: 0 -15px;
  }
  .ant-modal-footer{
    padding:15px 15px 0;
    margin: 0 -15px;
  }
  .pdf-viewer{
    height: 60vh !important;
  }
  .loadding-content {
    height: 60vh !important;
  }
  
}