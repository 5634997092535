.banner-frame {
  width: 100%;
  height: 100%;
  transform: translateX(23px);
  overflow: hidden;

  // border: 1px solid red;
  .out {
    width: 100%;
    height: calc(100% - 100px);
    position: relative;
    top: 100px;

    .inner {
      width: 100%;
      height: 100%;

      // transform: translateX(5%);
      .imgDiv {
        position: absolute;
        transition: 0.3s;
        width: 50%;
      }

      img {
        width: 100%;
        height: auto;
      }

      #last {
        transform: translateX(0px);
        z-index: 9;
        opacity: 1;
      }

      #first {
        transform: translateX(50%) scale(1.3);
        // transform: translateX(100%);
        z-index: 99;
        opacity: 1;
      }

      #second {
        transform: translateX(95%);
        z-index: 9;
        opacity: 1;
      }

      #left {
        transform: translateX(-100px);
        z-index: 1;
        opacity: 0;
      }

      #right {
        transform: translateX(400px);
        z-index: 1;
        opacity: 0;
      }
    }

    .button {
      display: flex;
      gap: 20px;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-100%);

      p {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, .2);
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;


  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-slider {
  max-width: 100%;

  img {
    max-width: 100%;
  }
}
.swiper-pagination-bullet {
  background-color: rgba(255,255,255, 0.8)
  
}
.swiper-pagination-bullet-active {
  background-color: rgba(255,255,255, 1) !important;
}