.animation-customize {
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.layout-footer-container{
  // height: 100px;
  padding: 5.9rem 16rem 6rem 15.9rem;
  background-color: #325CF1;
  display: flex;
  justify-content: space-between;
  &.animation__footer {
    .left {
      img {
        animation-name: fadeInRight;
        @extend .animation-customize;
      }
      p {
        @extend .animation-customize;
        animation-name: fadeIn;
        animation-delay: 0.7s;
      }
    }
    .right {
      animation-name: fadeInUp;
      @extend .animation-customize;
    }
    .center {
      a{
        // opacity: 0;
        @extend .animation-customize;
        animation-name: fadeInRight;
      }
    }
    .footer{
      // opacity: 0;
      @extend .animation-customize;
      animation-name: fadeIn;
      animation-delay: 1s;
    }
  }
  .left {
    img{width: 20rem;height: auto;
      // opacity: 0;
    }
    p{
      // opacity: 0;
      margin-top: 8px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #E2E9FF;
      line-height: 16px;
    }
  }
  .center,.footer {
    display: none;
  }
  .right { 
    // opacity: 0;
    padding-top: 10px;
    span {
      text-decoration: none;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 16px;
      cursor: pointer;
      & + span{
        margin-left: 24px;
      }
    }
  }
}
@media screen and (max-width: 750px){
    
  .layout-footer-container {
    flex-direction: column;
    padding:2.95rem 2.3rem 0;
    .left {
      p{display: none;}
      img{
        width: 16.8rem;
      }
    }
    .center {
      display: flex;
      gap: 30px;
      margin-top: 1.8rem;
      display: none;
      a {
        img {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
    .right {
      padding: 3.4rem 0 3rem;
    }
    .footer {
      display: block;
      border-top: 1px solid #466FFF;
      margin: 0 -23px 0;
      // text-align: center;
      padding: 23px 23px 36px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #E2E9FF;
      line-height: 14px;
    }
  }
}