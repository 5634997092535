.animation-customize {
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.home {
  overflow: hidden;
  background-color: #F9F9F9;
  background-image: url('../../assets/images/top-blue-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .banner-container {
    height: 1086.57px;
    padding-top: 89px;
    display: flex;
    // background-color: #f9f9f9;
    // justify-content: space-between;
    justify-content: center;
    // background-image: url('../../assets/images/top-blue-bg.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    transition: .1s;
    gap: 30px;
    .left {
      padding:300px 0 0 160px;
      width: 634px;
      h1 {
        font-size: 64px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 75px;
        opacity: 0;
        animation-name: bounceIn;
        @extend .animation-customize;
      }
      p{
        margin-top: 18px;
        font-size: 32px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        opacity: 0;
        animation-name: fadeIn;
        @extend .animation-customize;
        animation-delay: 0.6s;
      }
      .buttons{
        margin-top: 74px;
        img{
          width: 150px;
          height: 46px;
          opacity: 0;
          animation-name: fadeIn;
          @extend .animation-customize;
          &:nth-child(1){animation-delay: 0.9s;}
          &:nth-child(2){animation-delay: 1.2s;}
          & +img {
            margin-left: 15px;
          }
        }
      } 
    }
    .right {
      width: 485px;
      height: 590px;
      margin:81px 160px 0 0;
      overflow: hidden;
      // border: 1px solid red;
      .ant-carousel {
        overflow: hidden;
      }
      .banner-img {
        position: relative;
        text-align: center;
        overflow: hidden;
        img {
          margin: 0;
          display: inline-block;
          &:nth-child(2){
            position: relative;
            z-index: 2;
            left: 50px;
          }
          &:nth-child(1),&:nth-child(3){
            position: absolute;
            top: 0;
            transform: scale(0.75);
            z-index: 1;
          }
          &:nth-child(1){left: 0;}
          &:nth-child(3){right:-99px}
        }
      }
    }
  }
  .card-container {
    position: relative;
    z-index: 1;
    margin-top: -187px;
    padding: 0 0 0;
    text-align: center;
    // background-color: #F9F9F9;
    // background-image: url(../../assets/images/bg.png);
    // background-size: 100% 300px;
    // background-repeat:  no-repeat;
    // background-position: bottom;
    // &::after{
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   left: 0;
    //   top: 0;
    //   background-image: url(../../assets/images/bg.png);
    //   background-size: 100% 817px;
    //   background-repeat:  no-repeat;
    //   background-position: bottom;
    // }
    &.show .card-item{
      &:nth-child(1){animation-name: fadeInLeft;}
      &:nth-child(2){animation-name: fadeInUp;}
      &:nth-child(3){animation-name: fadeInRight;}
    }
    .bg{
      position: absolute;
      width: 100%;
      height:191px;
      left: 0;
      bottom: 0;
      background-image: url(../../assets/images/bg.png);
      background-size: 100% 817px;
      background-repeat:  no-repeat;
      background-position: bottom;
      z-index: -1;
    }
    .card-list {
      padding:0 160px 191px;
      display: flex;
      // align-items: flex-start;
      // align-content: stretch;
      justify-content: center;
      gap: 24px;
      position: relative;
     
      .card-item {
        box-sizing: border-box;
        flex: 1;
        max-width: 548px;
        // flex-basis:  33.33%;
        // width: 33.33%;
        // min-height: 383px;
        opacity: 0;
        // background: #FCFCFD;
        // box-shadow: 0px 33px 53px -40px rgba(15,15,15,0.05);
        // border-radius: 17px 17px 17px 17px;
        
        @extend .animation-customize;
        // &:nth-child(1).show{animation-name: fadeInLeft;}
        // &:nth-child(2).show{animation-name: fadeInUp;}
        // &:nth-child(3).show{animation-name: fadeInRight;}
        > div {
          height: 100%;
          box-sizing: border-box;
          // min-height: 383px;
          padding:50px 26px 50px;
          background: #FCFCFD;
          box-shadow: 0px 33px 53px -40px rgba(15,15,15,0.05);
          border-radius: 17px 17px 17px 17px;
        }
        // border: 1px solid purple;
        img {
          width: 16rem;
          height: 16rem;
        }
        h4 {
          padding-top:40px;
          font-size: 20px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #23262F;
          line-height: 24px;
        }
        p {
          padding-top: 16px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #777E90;
          line-height: 24px;
          word-break: break-word;
        }
      }
    }
  }
  section.privacy {
    background: #FFFFFF;
    display: flex;
    // justify-content: space-between;
    padding-left:160px;
    margin-top: -120px;
    justify-content: center;
    gap: 35px;
    // border: 1px solid red;
    .left {
      max-width: 448px;
      padding-top: 110px;
      @extend .animation-customize;
      opacity: 0;
      h1 {
        margin-top: 30px;
        font-size: 64px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #23262F;
      }
      p {
        margin-top: 16px;
        font-size: 32px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777E90;
      }
    }
    .right {
      padding-top: 107px;
      @extend .animation-customize;
      opacity: 0;
      img {
        width: 800px;
        // transform: translateX(60px);
      }
    }
  }
  section.live {
    position: relative;
    background: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 220px 160px 0;
    &[data-lang='en']{background-image:url(../../assets/images/live_en@2x.png);}
    &[data-lang='tr']{background-image:url(../../assets/images/live_tr@2x.png);}
    &[data-lang='jp']{background-image:url(../../assets/images/live_jp@2x.png);}
    &[data-lang='zh_CN']{background-image:url(../../assets/images/live_zh_CN@2x.png);}
    &[data-lang='zh_TW']{background-image:url(../../assets/images/live_zh_TW@2x.png);}
    &.show {
      animation-name: liveCenterBg;
      @extend .animation-customize;
      .live-item{animation-name: fadeInUp;}
      // .live-center-bg{animation-name: zoomIn;}
    }
    // border: 1px solid black;
    .live-item {
      flex: 50%;
      width: 300px;
      box-sizing: border-box;
      // min-height: 340px;
      opacity: 0;
      @extend .animation-customize;
      &:nth-child(1),&:nth-child(4){
        padding-right: 263px; 
      }
      &:nth-child(2),&:nth-child(5){
        padding-left: 263px;
      }
      &:nth-child(1).show{ animation-name: fadeInUp}
      // &:nth-child(2){ }
      &:nth-child(4){  
        margin-top: 171px; 
        margin-bottom: 269px; 
      }
      &:nth-child(5){
        margin-top: 215px;
        &.show{ animation-name: fadeInUp}
      }
      &:nth-child(3){
        height: 100%;
        width: 100vw;
        margin: 30px -30px 10px -40px;
        padding: 0 !important;
        opacity: 0;
        background-color: transparent;
        text-align: center;
        @extend .animation-customize;
        img{height: 100%;width: auto;}
      }
      h1 {
        font-size: 40px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #23262F;
        line-height: 56px;
      }
      p {
        margin-top: 16px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777E90;
        line-height: 26px;
      }
    }
  }
}

@media screen and (max-width: 750px){
  .home {
    background-image: url(../../assets/images/phone/top_blue_bg.png);
    background-repeat: no-repeat;
    .banner-container {
      position: relative;
      z-index: 1;
      flex-direction: column;
      height: auto;
      background-color: transparent;
      background-image: unset;
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      padding-bottom: 355px;
      .left {
        padding: 0 23px;
        width: auto;
        text-align: center;
        h1 {
          font-size: 32px;
          line-height: 38px;
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          line-height: 22px;
        }
        .buttons{
          margin-top: 31px;
          img{
            width: 123.81px;
            height: 37.97px;
            & +img {
              margin-left: 12px;
            }
          }
        } 
      }
      .right {
        width: 100%;
        margin: 0;
        // height: 500px;
      }
    }
    .card-container {
      position: relative;
      z-index: 2;
      margin-top: -260px;
      padding: 104px 0 156px;
      background: none;
      // background-image: url(../../assets/images/phone/bg.png);
      // background-size: 100% 100%;
      // background-repeat:  no-repeat;
      // border-top: 1px solid red;
      overflow: hidden;
      &.show .card-item{
        &:nth-child(1){animation-delay: 0.3s;}
        &:nth-child(2){animation-name: 0.6s;}
        &:nth-child(3){animation-name: 0.9s;}
      }
      &::before{
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 400px;
        background-image: url(../../assets/images/phone/bg.png);
        background-size: 100% 1325px;
        background-repeat:  no-repeat;
        background-position: bottom center;
      }
      .bg{display: none;}
      .card-list {
        flex-direction: column;
        gap: 32px;
        padding: 0 39px 0 38px;
        .card-item {
          width: 100%;
          height: auto;
          min-height: auto;
          box-sizing: border-box;
          padding: 0;
          border-radius: 17px 17px 17px 17px;
          position: relative;
          z-index: 3;
          > div {
            min-height: auto;
            position: relative;
            background: #FCFCFD;
            padding:50px 26px 50px;
            box-shadow: 0px 33px 53px -40px rgba(15,15,15,0.05);
            z-index: 2;
          }
          &:nth-child(2)::after{
            position: absolute;
            content: '';
            width: 192px;
            height: 192px;
            bottom: -46px;
            right: -153px;
            background-image: url(../../assets/images/phone/Group_100.png);
            z-index: 1;
          }
          &:nth-child(3)::before{
            position: absolute;
            content: '';
            width: 192px;
            height: 192px;
            bottom: -10px;
            left: -62px;
            background-image: url(../../assets/images/phone/Group_1286.png);
            background-repeat: no-repeat;
            z-index: 1;
          }
          img {
            width: 134px;
            height: 134px;
          }
          h4 { 
            font-size: 15px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #23262F;
            line-height: 20px;
          }
          p {
            font-size: 12px;
            font-family: Poppins-Regular, Poppins;
            font-weight: 400;
            color: #777E90;
            line-height: 20px;
          }
        }
      }
    }
    section.privacy {
      flex-direction: column;
      padding:23px 0 57px ;
      margin-top: -40px;
      .left {
        padding: 0 23px;
       
        img {
          width: 106px;
          height: 106px;
          margin-left: 21px;
        }
        h1 {
          margin-top: 29px;
          font-size: 22px;
          line-height: 30px;
          text-align: center;
        }
        p {
          margin-top: 16px;
          font-size: 15px;
          text-align: center;
        }
      }
      .right {
        margin-top: 32px;
        padding-top: 0;
        img {
          width: 100%;
          transform: translateX(0);
        }
      }
    }
    section.live {
      // margin-top: 57px;
      background-image: url(../../assets/images/phone/Rectangle.png) !important;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 145px 30px 64px 40px;
      overflow: hidden;
      animation-name: none !important;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: -4px;
        width: 469px;
        height: 469px;
        background-image: url(../../assets/images/phone/live_left_bg.png);
        background-repeat: no-repeat;
      }
      &::after {
        position: absolute;
        content: '';
        right: 230px;
        top: 42px;
        width: 377px;
        height: 377px;
        background: linear-gradient(168deg, rgba(223,234,255,0.94) 18%, rgba(255,255,255,0) 100%);
        opacity: 0.2;
        border-radius: 50%;
      }
      .live-item { 
        min-height: auto;
        width: 100%;
        flex: 100%;
        padding:30px 24px !important;
        background: #FFFFFF;
        border-radius: 20px 20px 20px 20px;
        &:nth-child(4){  
          margin-top: 0 !important; 
          margin-bottom: 0; 
        }
        &.live-center-bg {
          margin: 0;
          height: fit-content;
          margin: 30px -30px 10px -40px !important;
          img {
            width: 100%;
            height: auto;
          }
        }
        h1 {
          font-size: 20px;
          color: #325CF1;
          line-height: 30px;
        }
        p {
          margin-top: 20px;
        }
        & + .live-item {
          margin-top:20px ;
        }
      }
    }
  }
}




// 动画
@keyframes liveCenterBg {
  from {
    opacity: 0;
    background-size:0% 0%;  
  }
  to{
    opacity: 1;
    background-size: 672px auto;
  }
}
@keyframes bounceIn {
  0%,20%,40%,60%,80%,to {
      -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      -webkit-transform: scale3d(.3,.3,.3);
      transform: scale3d(.3,.3,.3)
  }

  20% {
      -webkit-transform: scale3d(1.1,1.1,1.1);
      transform: scale3d(1.1,1.1,1.1)
  }

  40% {
      -webkit-transform: scale3d(.9,.9,.9);
      transform: scale3d(.9,.9,.9)
  }

  60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03,1.03,1.03);
      transform: scale3d(1.03,1.03,1.03)
  }

  80% {
      -webkit-transform: scale3d(.97,.97,.97);
      transform: scale3d(.97,.97,.97)
  }

  to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes zoomIn {
  from {
      opacity: 0;
      -webkit-transform: scale3d(.3,.3,.3);
      transform: scale3d(.3,.3,.3)
  }

  to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
  }
}
.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn
}
@keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(0,100%,0);
      transform: translate3d(0,100%,0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

