.account-delete-conatainer {
  padding-top:var(--headerHeight);
  .content-box {
    width: 100%;
    max-width: 54.44444444444444%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .title-text {
    display: none;
    margin-top: 3rem;
    text-align: center;
    font-size: 1.6rem;
    font-weight:500;
  }
  .steps{
    margin: 6rem 0 4rem;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .intervalBlock {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      height: 4rem;
      span{
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        background-color: #C7D3FF;
        border-radius: 50%;
      }
    }
    .step-item {
      flex: 1;
      max-width: 20rem;
      text-align: center;
      &.actived {
        .number {
          background-color:#325CF1 !important;
        }
        .description{
          color: #111111;
        }
      }
      .number-border{
        display: inline-block;
        // width: 3.6rem;
        // height: 3.6rem;
        border-radius: 3.2rem;
        padding: 0.4rem;
        border: 0.1rem solid #C7D3FF;
        box-sizing: border-box;
        .number{
          height: 2.8rem;
          width: 2.8rem;
          background: rgba(50,92,241,0.4);
          border-radius: 2.4rem;
          text-align: center;
          line-height: 2.8rem;
          font-size: 1.5rem;
          font-family: MiSans-Medium, MiSans;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
      .description {
        margin-top: 1.5rem;
        font-size: 1.4rem;
        font-family: MiSans-Medium, MiSans;
        font-weight: 500;
        color: #999999;
        line-height: 2rem;
      }
    }
  }
  .content {
    padding: 4rem 0 6rem;
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .form-box {
    width: 100%;
    max-width: 50rem;
    .ant-form-item{
      margin-bottom: 2rem;
      label {
        font-size: 1.8rem;
        font-family: MiSans-Regular, MiSans;
        font-weight: 400;
        color: #000000;
        line-height: 2.4rem;
      }
      .ant-input,Button {
        height: 5rem;
      }
      .ant-form-item-explain-error{
        line-height: 2rem;
      }
    }
    .verification-item {
      display: flex;
      align-items: flex-end;
      gap: 1.5rem;
      .code-item {
        flex: 1;
      }
      .code-btn {
        padding: 1.3rem 2rem;
        font-size: 1.5rem;
        font-family: MiSans-Regular, MiSans;
        font-weight: 400;
        color: #325CF1;
        line-height: 2.4rem;
        border-radius: 0.4rem;
        opacity: 1;
        border: 0.1rem solid #325CF1;
        min-width: 19rem;
      }
    }
    .next-btn {
      margin-top: 2rem;
      width: 15.9rem;
      height: 5rem;
      background: #325CF1;
      border-radius: 0.4rem;
      color: #FFFFFF;
      font-size: var(--size18);
      font-family: MiSans-Regular, MiSans;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 2.4rem;
    }
  }
  .step-1 {
    .top{
      display: flex;
      align-items: center;
      img {width: 3.2rem;height: 3.2rem;}
      .label {
        margin-left: 0.8rem;
        font-size: 1.8rem;
        font-family: MiSans-Regular, MiSans;
        font-weight: 400;
        color: #111111;
        line-height: 3.2rem;
      }
    }
    .description {
      margin-top: 1rem;
      font-size: 1.6rem;
      font-family: MiSans-Regular, MiSans;
      font-weight: 400;
      color: #111111;
      line-height: 2.6rem;
    }
    .delete-btn {
      margin-top: 4rem;
      padding:1.3rem 6rem;
      height: 5rem;
      background: #325CF1;
      border-radius: 0.4rem;
      color: #FFFFFF;
    }
  }
  .step-2 {
    text-align: center;
    img{
      width: 6rem;
      height: 6rem;
    }
    .description {
      margin-top: 3rem;
      text-align: center;
      p:nth-child(1){
        font-size: 1.8rem;
        font-family: MiSans-Regular, MiSans;
        font-weight: 400;
        color: #111111;
        line-height: 3.2rem;
      }
      p:nth-child(2){
        margin-top: 0.4rem;
        font-size: 1.6rem;
        font-family: MiSans-Regular, MiSans;
        font-weight: 400;
        color: #111111;
        line-height: 2.6rem;
      }
      .account {

      }
    }
  }
}
.my-message{
  .ant-message-notice-content{
    padding: 1.5rem 2rem !important;
    .ant-message-info{
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.8rem;
      font-family: MiSans-Regular, MiSans;
      font-weight: 400;
      color: #111111;
      line-height: 2.4rem;
    }
  }
}
.confirm-modal {
  .ant-modal-content{
    .ant-modal-close{display: none;}
    padding: 3rem;
    .text{
      margin: 3rem 0 6rem;
      font-size: 1.8rem;
      font-family: MiSans-Regular, MiSans;
      font-weight: 400;
      color: #111111;
      line-height: 2.4rem;
    }
  }
  .ant-modal-footer {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    .ant-btn {
      height: 4.4rem;
      width: calc(50% - 1rem);
      margin: 0;
      border-radius: 0.8rem;
      color: #ffffff;
      box-shadow: none;
      border: none;
      &:nth-child(2){
        order: 1;
        background-color: #C7D3FF;
        margin-inline-start: 0 !important;
      }
      &:nth-child(1){
        order: 2;
        background-color: #325CF1;
      }
    }
  }
}
@media screen and (max-width: 750px){
  .account-delete-conatainer {
    padding-top:var(--headerPhoneHeight);
    .content-box{
      max-width: none;
      padding: 0 2rem 4rem;
      .title-text{
        display: block;
      }
      .steps {
        margin: 3rem 0 2rem;
        .intervalBlock{
          gap: 0.2rem;
          span{
            height: 0.3rem;
            width: 0.3rem;
          }
        }
        .step-item .description{
          font-size: 1.3rem;
          line-height: 1.4rem;
          margin-top: 0.75rem;
        }
      }
    }
    .content{
      padding: 0;
    }
    .form-box {
      .ant-form-item{
        label{
          font-size: 1.3rem;
          line-height: 1.8rem;
        }
        .ant-input,Button {
          height: 5rem;
        }
      }
      .verification-item{
        gap: 0.75rem;
        .code-btn {
          padding: 0.75rem 1rem;
          font-size: 1.3rem;
          line-height: 1.8rem;
          min-width: 15rem;
        }
      }
      .next-btn{
        margin-top: 1rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }
    .step-1 {
      .top{
        .label {
          
          font-size: 1.6rem;
        }
      }
      .description {
        font-size: 1.3rem;
      }
      .delete-btn {
        margin-top: 2rem;
      }
    }
    .step-2 {
      img {
        width: 3rem;
        height: 3rem;
      }
      .description{
        margin-top: 1.5rem;
        p:nth-child(1){
          font-size: 1.5rem;
          line-height: 1.8rem;
        }
        p:nth-child(2){
          font-size: 1.3rem;
          line-height: 1.6rem;
        }
      }
    }
  }
  .confirm-modal {
    .ant-modal-content {
      padding: 1.5rem;
      .text{
        margin: 1.5rem 0 3rem;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .ant-modal-footer{
      .ant-btn{
        // width: calc(50% - 0.8rem);
      }
    }
  }
}