.loading-container{
    position: fixed;
    z-index: 10001;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    display: none;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: display .3s;
    &.show{
        display: flex;
        opacity: 1;
    }
    .loading-box {
        text-align: center;
        .heartbeat {
            // transition: all ;
            animation: scaleLoading 1s linear infinite alternate;
        }
        .loading-tips {
            margin-top: 2rem;
        }
    }
}
@keyframes scaleLoading {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}